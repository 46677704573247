import React from 'react';

const PackageUpdate = () => {
    return (
        <div>
            <h2 className=' text-center my-5 text-info'>Package Update is Comming Soon ...........</h2>
        </div>
    );
};

export default PackageUpdate;