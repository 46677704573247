import React from 'react';
import NarairLogo from '../../../assets/logo/nag-air-logo-small.png'
import './TicketBookingSingleSection.css'

const TicketBookingSingleSection = (bookingData) => {
    // console.log("bookingInfo : ", bookingData);
    const { bookingInfo: ticketData, travellers, user } = bookingData;
    return (
        <div>
            <div className=' ticketOne px-4 pt-3 '>
                <img src={NarairLogo} className='nagairLogo' alt="" />
                <p > <b>Travel :  </b> <span className=' text-uppercase'> {ticketData?.flightFromCurrentLocation}</span> -
                    <span className=' text-uppercase'>{ticketData?.flightToDestinationLocation}</span> </p>

                <p> <b>Plane: </b>{ticketData?.planeNumber} <span className=' mx-2'>and</span> <b>Flight No: </b>{ticketData?.flightNumber}</p>

                <p> <b>Departure: </b> {new Date(ticketData.flightDepartingDate).toLocaleDateString()}({ticketData.flightDepartingTime})-
                    <b>Arrival: </b> {new Date(ticketData?.flightArrivalDate).toLocaleDateString()}({ticketData.flightArrivalTime})</p>
                <p> <b>Package Price:</b> {ticketData.packagePrice} <span className=' mx-2'>and</span>
                    <b>Travellers: </b> {(travellers > 1) ? <>{travellers} Persons </> : <>{travellers} Person</>}
                    <span className=' mx-2'>~~</span>
                    <b>Total Fare: </b> {(ticketData?.packagePrice) * travellers}TK
                </p>
                <p> <b>Submitted Date: </b> {new Date().toString()}  </p>
                <p> <b>Printed By: </b> {user.email}  </p>
                <p className=' small pb-1 '>N.B. Nag air reserves the right to make any flight related decisions.</p>

            </div>
        </div>
    );
};

export default TicketBookingSingleSection;