import React from 'react';
import './HomeReadyToTakeOff.css';
// import imageO from '../../../../assets/image/original-faac6710f10920aec26f8a4ba97ae50e.png';
import pilot from '../../../../assets/image/pilot.png';

const HomeReadyToTakeOff = () => {
    return (

        <div className='takeOffArea'>
            <div className=' d-lg-flex flex-lg-row-reverse col-lg-11 mx-auto'>

                <div className='col-lg-6  text-center text-lg-start'>
                    <h1 className=' takeOffheading text-center'>NAG AIR</h1>
                    <div className='pilogBg col-xl-7 col-lg-9  col-md-6 col-9 mx-auto'>
                        <img className='pilot' src={pilot} alt="" />
                    </div>
                </div>

                <div className=' col-lg-6 d-flex flex-column justify-content-center text-center text-lg-start mt-lg-0 mt-4'>
                    <h1 className=' takeOffHeading2 fw-bold'>READY <br className='' /> TO TAKE OFF?</h1>
                    <p className=' fw-bold my-5'>Controlling everingthing with ai, we remain in <br />
                        authority & assist when it matters
                    </p>

                    <div className=' d-flex'>
                        <div className=' col-6'>
                            <h1 className=' fw-bold counter '>29+</h1>
                            <p className=' fw-bold'>Dedicated Boeing 007 <br /> Airliner</p>
                        </div>
                        <div className=' col-6'>
                            <h1 className=' fw-bold counter'>24/7</h1>
                            <p className=' fw-bold'>Air traffic control (ATC) <br />service</p>
                        </div>
                    </div>
                </div>



            </div>
            {/* <img src={imageO} className=' img-fluid' alt="" /> */}
        </div>
    );
};

export default HomeReadyToTakeOff;