import React from 'react';

const FlightInformationUpdate = () => {
    return (
        <div>
            <h2 className=' text-center my-5 text-info'>Flight Information  Update is Comming Soon ...........</h2>
        </div>
    );
};

export default FlightInformationUpdate;