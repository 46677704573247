import React from 'react';

const TripSummarySection = (flightBookingInfo) => {
    const tripSummary = flightBookingInfo.flightBookingInfo;
    return (
        <div className='tripOne px-md-4 py-1 mb-2' >
            <div className=' col-md-8    d-flex   mb-0'>
                <p className=' text-uppercase'>{tripSummary?.flightFromCurrentLocation}</p>
                <span className=' fw-bold mx-3'> <i> To</i></span>
                <p className=' text-uppercase'>{tripSummary?.flightToDestinationLocation}</p>
            </div>

            <p className=''>
                {new Date(tripSummary?.flightDepartingDate).toLocaleDateString()} ({tripSummary?.flightDepartingTime})
                <span className=' fw-bold'> <i> To </i></span>
                {new Date(tripSummary?.flightDepartingDate).toLocaleDateString()}({tripSummary?.flightArrivalTime})
            </p>


            <div className=' d-flex'>
                <p><b>Aeroplane  : </b>{tripSummary?.planeNumber},</p>
                <p className=' ms-2'><b>Flight : </b>{tripSummary?.flightNumber},</p>
                <b className=' ms-1'>{tripSummary?.packageName}</b>-{tripSummary?.packagePrice}
            </div>
        </div>
    );
};

export default TripSummarySection;