import React, { useRef } from 'react';
import useAuth from '../../../hooks/useAuth';
import { toast } from 'react-hot-toast';
import './TicketBookingPage.css';
import { useReactToPrint } from 'react-to-print';
import TicketBookingSingleSection from './TicketBookingSingleSection';
import { useNavigate } from 'react-router-dom';

const TicketBookingPage = () => {
    const { user, travellers, departureBookingInfo, returnBookingInfo, tripOneBookingInfo, tripTwoBookingInfo, tripThreeBookingInfo,
        setDepartureBookingInfo, setReturnBookingInfo, setTripOneBookingInfo, setTripTwoBookingInfo, setTripThreeBookingInfo,

    } = useAuth();

    // console.log("Search Data, selectedFlight, Traveller and totalFare :", searchData, selectedFlight, travellers, totalFare);
    const navigate = useNavigate();

    const handlePreviousData = () => {
        localStorage.removeItem('departure');
        localStorage.removeItem('return');
        localStorage.removeItem('tripOne');
        localStorage.removeItem('tripTwo');
        localStorage.removeItem('tripThree');
        setDepartureBookingInfo("");
        setReturnBookingInfo("");
        setTripOneBookingInfo("");
        setTripTwoBookingInfo("");
        setTripThreeBookingInfo("");

        // localStorage.removeItem('refresh');
    }

    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,

    });

    const handleFinalSuccess = () => {
        handlePreviousData();
        handlePrint();
        navigate('/');
    }

    console.log("departureBookingInfo :", departureBookingInfo);

    return (
        <div className=' '>
            <h3 className=' text-center fw-bold my-4'>Ticket Booking</h3>
            <div ref={componentRef} className="jsProFontSm col-md-10 mx-auto mt-5">
                <div className='ticketBooking px-3 py-3'>

                    {/* for oneway and roud trip departure flight booking */}
                    {(Object.keys(departureBookingInfo).length > 0) &&

                        <TicketBookingSingleSection
                            bookingInfo={departureBookingInfo}
                            travellers={travellers}
                            user={user}
                        />
                    }
                    {/*end of oneway and roud trip departure flight booking */}


                    {/*for roud trip departure flight booking */}
                    {(Object.keys(returnBookingInfo).length > 0) &&
                        <>
                            <h4 className=' text-center mt-4'>Print Ticket Two</h4>
                            <TicketBookingSingleSection
                                bookingInfo={returnBookingInfo}
                                travellers={travellers}
                                user={user}
                            />
                        </>
                    }
                    {/*end of roud trip departure flight booking */}


                    {/*multicity trip one flight booking */}
                    {(Object.keys(tripOneBookingInfo).length > 0) &&
                        <TicketBookingSingleSection
                            bookingInfo={tripOneBookingInfo}
                            travellers={travellers}
                            user={user}
                        />
                    }
                    {/*end of multicity trip one flight booking */}


                    {/*multicity trip two flight booking */}
                    {(Object.keys(tripTwoBookingInfo).length > 0) &&
                        <>
                            <h4 className=' text-center mt-4 my-1'>Ticket Two</h4>
                            <TicketBookingSingleSection
                                bookingInfo={tripTwoBookingInfo}
                                travellers={travellers}
                                user={user}
                            />
                        </>
                    }
                    {/*end of multicity trip two flight booking */}


                    {/*multicity trip three flight booking */}
                    {(Object.keys(tripThreeBookingInfo).length > 0) &&
                        <>
                            <h4 className=' text-center mt-4  '>Ticket Three</h4>
                            <TicketBookingSingleSection
                                bookingInfo={tripThreeBookingInfo}
                                travellers={travellers}
                                user={user}
                            />
                        </>
                    }
                    {/*end of multicity trip three flight booking */}
                </div>
            </div>
            <div className="col-md-10 mx-auto  d-flex my-2  justify-content-end">
                <button
                    onClick={handleFinalSuccess}
                    className="btn btn-primary ms-1 fw-bold"
                >Print Ticket
                </button>
            </div>
        </div>
    );
};

export default TicketBookingPage;