import React from 'react';
import { Link } from 'react-router-dom';
import './FlightSearchResultPage.css';
import useAuth from '../../../hooks/useAuth';
import RoundTripResult from './RoundTripResult';
import OneWayResult from './OneWayResult';

import MultiCityResult from './MultiCityResult';
import TripSummarySection from './TripSummarySection';
import SelectedFlightSection from './SelectedFlightSection';

const FlightSearchResultPage = () => {
    const { searchData, flights, travellers, setTotalFare, departureBookingInfo, returnBookingInfo, tripOneBookingInfo,
        tripTwoBookingInfo, tripThreeBookingInfo } = useAuth();

    // console.log("searchData : ", searchData);
    // console.log("Multicity trips Result :", trips);
    // console.log("selectedPackage :", selectedPackage);
    // console.log("selectedFlight :", selectedFlight);
    // console.log("flights : ", flights);
    // console.log("flights : ", flights.returnResults);

    let totalCost = 0;
    const handleTotalFare = () => {
        if (searchData.travelType === 'oneWay') {
            totalCost = (departureBookingInfo.packagePrice) * travellers;
            setTotalFare(totalCost);
            return totalCost;
        }
        if (searchData.travelType === 'roundTrip') {

            totalCost = (departureBookingInfo.packagePrice) * travellers + (returnBookingInfo.packagePrice) * travellers;
            setTotalFare(totalCost);
            return totalCost;
        }
        if (searchData.travelType === 'multiWay') {
            totalCost = parseInt(tripOneBookingInfo.packagePrice * travellers)
                + parseInt(tripTwoBookingInfo.packagePrice * travellers)
                + parseInt(tripThreeBookingInfo.packagePrice * travellers);
            setTotalFare(totalCost);
            return totalCost;
        }
    }

    console.log("handleTotalFare : ", handleTotalFare());


    return (
        <div>
            {Object.keys(flights).length > 0 ?
                <div>
                    <h2 className="text-center  fw-bold  mt-3">Available Flights</h2>
                    <div className=" d-flex  justify-content-end">
                        <Link to="/" className="fs-4 text-info text-center text-decoration-none  fw-bold  my-0">Search Again</Link>
                    </div>

                    {/* available flights */}
                    {searchData?.travelType === 'oneWay' ? <OneWayResult></OneWayResult>
                        : searchData?.travelType === 'roundTrip' ? <RoundTripResult></RoundTripResult>
                            : <MultiCityResult></MultiCityResult>
                    }

                    {/* trip summary and selected flights */}
                    <div className=''>
                        <div className='row'>
                            <div className=' col-md-8   '>
                                <h4>Trip Summary</h4>
                                {((Object.keys(departureBookingInfo).length === 0) &&
                                    (Object.keys(returnBookingInfo).length === 0) &&
                                    (tripOneBookingInfo.length === 0) &&
                                    (tripTwoBookingInfo.length === 0) &&
                                    (tripThreeBookingInfo.length === 0)) &&
                                    <>
                                        <h4 className=' selectedFlight  py-2 text-center'> Select Flight</h4>
                                    </>
                                }

                                {/* summary for oneWay and roundTrip */}
                                <div className=' '>
                                    {/* for departure */}
                                    {Object.keys(departureBookingInfo).length > 0 ?
                                        <TripSummarySection flightBookingInfo={departureBookingInfo} /> : <></>
                                    }


                                    {/* for return */}
                                    {Object.keys(returnBookingInfo).length > 0 ?
                                        <TripSummarySection flightBookingInfo={returnBookingInfo} /> : <></>
                                    }
                                </div>

                                {/* summary for multi city */}
                                {searchData.travelType === 'multiWay' ?
                                    <>
                                        {/* for trip one */}
                                        {Object.keys(tripOneBookingInfo).length > 0 ?
                                            <>
                                                <h5 className=' text-center'>Trip One</h5>
                                                <TripSummarySection flightBookingInfo={tripOneBookingInfo} />
                                            </> : <></>
                                        }


                                        {/* for trip two */}
                                        {Object.keys(tripTwoBookingInfo).length > 0 ?
                                            <>
                                                <h5 className=' text-center'>Trip Two</h5>
                                                <TripSummarySection flightBookingInfo={tripTwoBookingInfo} />
                                            </> : <></>
                                        }


                                        {/* for trip three */}
                                        <div >
                                            {Object.keys(tripThreeBookingInfo).length > 0 ?
                                                <>
                                                    <h5 className=' text-center'>Trip Three</h5>
                                                    <TripSummarySection flightBookingInfo={tripThreeBookingInfo} />
                                                </> : <></>
                                            }
                                        </div>
                                    </> : <></>
                                }
                            </div>

                            <div className=' col-md-4 '>
                                <h4>Your Selection</h4>
                                <div className=' selectedFlight px-1 py-1'>


                                    {/* departure of one way and round trip */}
                                    {(Object.keys(departureBookingInfo).length > 0) ?
                                        <SelectedFlightSection flightBookingInfo={departureBookingInfo} /> : <></>

                                    }
                                    {/*end departure of one way and round trip */}

                                    {/* returning of round trip */}
                                    {(Object.keys(returnBookingInfo).length > 0) ?
                                        <SelectedFlightSection flightBookingInfo={returnBookingInfo} /> : <></>

                                    }
                                    {/*end returning of round trip */}



                                    {/* multicity trip one */}
                                    {(Object.keys(tripOneBookingInfo).length > 0) ?
                                        <SelectedFlightSection flightBookingInfo={tripOneBookingInfo} /> : <></>
                                    }
                                    {/*end of multicity trip one */}

                                    {/* multicity trip two */}
                                    {(Object.keys(tripTwoBookingInfo).length > 0) ?
                                        <SelectedFlightSection flightBookingInfo={tripTwoBookingInfo} /> : <></>
                                    }
                                    {/*end of multicity trip two */}

                                    {/* multicity trip three */}
                                    {(Object.keys(tripThreeBookingInfo).length > 0) ?
                                        <SelectedFlightSection flightBookingInfo={tripThreeBookingInfo} /> : <></>
                                    }
                                    {/*end of multicity trip two */}


                                    {isNaN(handleTotalFare()) ?
                                        <>
                                            <h4 className=' text-center'> Select Flight</h4>
                                        </> :
                                        <>
                                            <div className=' fs-4 text-center'>
                                                <span className=' mb-0'>Booking total amount</span>  <br />
                                                <b className=' mt-0'>{handleTotalFare()}TK</b>
                                                {console.log("handleTotalFare :", handleTotalFare())}
                                            </div>

                                            <div className=' text-center mt-2'>
                                                <Link to="/ticketBooking" className=' btn btn-success w-50 fw-bold'>Confirm Book</Link>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>


                </div >
                :
                <div className=' my-5'>
                    <div className=' fs-3 text-center fw-bolder'>No Flights Found According to Your Search Data</div>
                    <p className=' fs-3 text-info text-center fw-bold my-5'>Please Search again with different data</p>
                    <div className="text-center ">
                        <Link to="/" className="fs-4 text-center text-decoration-none my-3  fw-bold btn  btn btn-outline-primary btn-sm">Go to Search</Link>
                    </div>

                </div>
            }
        </div >
    );
};

export default FlightSearchResultPage;