import React from 'react';
import useAuth from '../../../hooks/useAuth';

const SelectedFlightSection = (flightBookingInfo) => {
    const { travellers, } = useAuth();
    const tripSummary = flightBookingInfo.flightBookingInfo;
    return (
        <div>
            <p className=' text-uppercase text-center  mb-0'>
                {tripSummary?.flightFromCurrentLocation}
                <span className=' mx-2 '> <i>   To  </i></span>
                {tripSummary?.flightToDestinationLocation}
            </p>

            <p className=' text-center mt-0'>
                {new Date(tripSummary?.flightDepartingDate).toLocaleDateString()} ({tripSummary?.flightDepartingTime})
                <span className=' mx-2'> <i> To </i></span>
                {new Date(tripSummary?.flightDepartingDate).toLocaleDateString()}({tripSummary?.flightArrivalTime})

                <br />    Travellers: {travellers},
                {tripSummary?.packageName}-{tripSummary?.packagePrice}
            </p>
        </div>
    );
};

export default SelectedFlightSection;