import React from 'react';

const DomesticFlightsLocationUpdate = () => {
    return (
        <div>
            <h2 className=' text-center my-5 text-info'>Domestic Flight Update is Comming Soon ...........</h2>
        </div>
    );
};

export default DomesticFlightsLocationUpdate;